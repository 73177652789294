import React, { FC, useEffect, useState, useRef } from 'react'
import { BorderedPageLayout } from '../../../pages/BorderedPageLayout'
import { PatientItem } from '../../ListItems/PatientItem'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { handleOrderInfoModal } from '../../../features/modals/modalsSlice'
import { ModalContentProps } from '../../Modal'
import { OrderItem } from '../../OrderItem'
import Skeleton from 'react-loading-skeleton'
import { getOrdersByPatientId, incrementPatientOrdersPart, resetPatientOrders } from '../../../features/current-data/currentData'
import { usePagination } from '../../../hooks/usePagination'
import { normalizeDate } from '../../../utils/normalizeDate'
import { YellowButton } from '../../YellowButton'
import { CartItem } from '../../ListItems/CartItem'
import useTheme from '../../../hooks/useAppColor'
import { user } from '@telegram-apps/sdk/dist/dts/scopes/components/init-data/init-data'

export const CreateTemplateSheetModalContent: FC<ModalContentProps> = ({ handleModal, level }) => {
    const dispatch = useAppDispatch()
    const getTheme = useTheme()

    const cartProducts = useAppSelector(state => state.cart.items)
    const cartTotalPrice = useAppSelector(state => state.cart.totalPrice)
    const [inputValue, setInputValue] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
      };

    return (
        <BorderedPageLayout
            modal={{ level: level || 1 }}
            handleModal={handleModal}
            contentClassName='f-column gap-40'>
            <div className="d-f jc-between">
                <div onClick={handleModal} className="textButton fz-l">Закрыть</div>
                <div className='f-025'></div>
            </div>
            <div className={`d-f pd-10 al-center gap-5 search-block w-100p ${getTheme("", "app-bg-dark svg-gray-filled")}`}>
                <input  onChange={handleInputChange} className={`w-100p fz-m ${getTheme("", "c-white")}`} type="text" placeholder='Введите название шаблона' />
            </div>
            <div className="f-column gap-15 f-1">
                <div className="f-1 p-rel">
                    <div className="list p-abs w-100p f-column scrollableItemsList gap-10">
                        {
                            cartProducts.map(item => (
                                <CartItem
                                    item={item} />
                            ))
                        }
                    </div>
                </div>

                <div className="p-rel">
                    <p className={`${getTheme("c-dark", "c-white")} fz-l fw-9`}>Итого: {cartTotalPrice - ((cartTotalPrice/ 100) * 10)}&nbsp;₽</p>
                </div>

                <YellowButton disabled={inputValue === ""} className='f-c-row gap-10' onClick={() => {console.log(inputValue)}}>Сохранить шаблон</YellowButton>

            </div>
        </BorderedPageLayout>
    )
}
