import React, { FC } from 'react'
import { Modal, ModalProps } from '../../Modal'
import { TemplateSheetUserInfoModalContent } from './content'
import { useAppDispatch } from '../../../app/hooks'
import { handleTemplateSheetModal} from '../../../features/modals/modalsSlice'


export const TemplateSheetUserInfoModal: FC<ModalProps> = ({ opened, level }) => {
    const dispatch = useAppDispatch()

    const handleModal = () => {
        dispatch(handleTemplateSheetModal())
    }
    return (
        <Modal opened={opened}>
            <TemplateSheetUserInfoModalContent level={level} handleModal={handleModal} />
        </Modal>
    )
}
