import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { UserApi } from "../../http/api/user.api";
import { AuthAcceptReq, AuthAcceptRes, AuthSignDocxReq, AuthSignDocxRes, EditDocumentsReq, ProfileDocumentsGetRes, EditDocumentsRes } from "../../types/api/user.api.types";
import { deleteTokens, storeTokens } from "../../utils/storeTokens";
import { getCookie } from "../../utils/CookieUtil";
import { handleTokenRefreshedRequest } from "../../utils/handleThunkAuth";
import { useAppSelector } from "../../app/hooks";

const phone = getCookie("phone")

type docsEditSliceType = {
    access_token: string
    token: {
        valid: boolean | null,
        checking: boolean
    },
    auth: {
        loading: boolean,
        success: {
            phone: boolean | null
            code: boolean | null
        },
        code_options: {
            is_freezed: boolean
            freezed_sec: number
        },
        form: {
            maskedPhone: string
            phone: string
            password: string
        },
        errors: {
            phone: string
            code: string
        }
    }
}

const initialState: docsEditSliceType = {
    access_token: "",
    token: {
        checking: true,
        valid: null
    },
    auth: {
        loading: false,
        success: {
            phone: phone,
            code: null
        },
        code_options: {
            is_freezed: false,
            freezed_sec: 0
        },
        form: {
            maskedPhone: phone?.maskedPhone || '+7',
            phone: phone?.phone || '',
            password: ""
        },
        errors: {
            phone: "",
            code: ""
        }
    }
}

export const sendEditSignCode = createAsyncThunk(
        'docs_edit_sign/code',
        async (req: AuthAcceptReq, { dispatch }) => {
            const res: AxiosResponse<AuthAcceptRes> = await UserApi.LoginCode(req)
            if (!res.data) {
                throw new Error("Ошибка сервера!")
            }
            if (res.status === 401) {
                throw new Error("Неверный код!")
            }
    
            return res.data
        }
    )

    export const getDocuments = createAsyncThunk(
        'documents/get',
        async (_, { dispatch }) => {
            const res: AxiosResponse<ProfileDocumentsGetRes> = await handleTokenRefreshedRequest(null, UserApi.GetDocuments)
    
            return res.data
            // return new Promise<ProfileData>((res, rej) => {
            //     setTimeout(() => {
            //         res({
            //             first_name: "Борис",
            //             last_name: "Борисов",
            //             subname: "Борисович",
            //             dob: "2000-11-11",
            //             image: "/",
            //             bonus: 3,
            //             gender: false
            //         })
            //     }, 1000)
            // })
        }
    )

    export const changeDouments = createAsyncThunk(
        'profile_document/add',
        async (req: EditDocumentsReq, {dispatch}) => {
            const res: AxiosResponse<EditDocumentsRes> = await handleTokenRefreshedRequest(null, UserApi.EditDocuments, req)
            if(!res.status){
                throw new Error("Не удалось изменить Ваши документы")
            }
            return {...res.data, ...req}
        }
    )

export const docsEditSlice = createSlice({
    name: "docsEdit",
    initialState,
    reducers: {
        handleDocsEditForm: (state, action: PayloadAction<{ key: keyof typeof initialState.auth.form, val: string }>) => {
            if (state.auth.errors.code) {
                state.auth.errors.code = ""
            }
            if (state.auth.errors.phone) {
                state.auth.errors.phone = ""
            }
            state.auth.form[action.payload.key] = action.payload.val
        },
        setCodeIsFreezed: (state, action: PayloadAction<boolean>) => {
            state.auth.code_options.is_freezed = action.payload
        },
        setCodeFreezedSecs: (state, action: PayloadAction<number>) => {
            state.auth.code_options.freezed_sec = action.payload
        },
        resetDocsEditForm: (state) => {
            state.auth = initialState.auth
        },
        setValidToken: (state, action: PayloadAction<boolean>) => {
            state.token.valid = action.payload
        },
        resetDocsEditCodeStatus: (state) => {
            state.auth.success.code = null
        },
        resetDocsEditPhoneStatus: (state) => {
            state.auth.success.phone = null
        }
    },
    extraReducers: (builder) => {
        //SEND PHONE IN DocsEdit
        builder.addCase(sendEditSignCode.pending, (state, action) => {
            state.auth.loading = true
            state.auth.success.phone = null
            state.auth.errors.phone = ""
        })
        
        builder.addCase(sendEditSignCode.fulfilled, (state, action) => {
            storeTokens({ refresh: action.payload.refresh, access: action.payload.access })
            state.auth.loading = false
            state.auth.success.code = true
            state.token.valid = true
            state.auth.form = initialState.auth.form

        })
        builder.addCase(sendEditSignCode.rejected, (state, action) => {
            const isBadCode = action.error.code === "ERR_BAD_REQUEST"
            state.auth.loading = false
            state.auth.success.code = false
            state.auth.errors.code = String(isBadCode ? "Неверный код" : action.error.message)
        })
    },
})

export const {
    handleDocsEditForm,
    resetDocsEditForm,
    resetDocsEditCodeStatus,
    setValidToken,
    resetDocsEditPhoneStatus,
    setCodeFreezedSecs,
    setCodeIsFreezed,
} = docsEditSlice.actions


export const docsEditReducer = docsEditSlice.reducer