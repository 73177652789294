import React, { FC, useEffect, useState, useRef } from 'react'
import { BorderedPageLayout } from '../../../pages/BorderedPageLayout'
import { PatientItem } from '../../ListItems/PatientItem'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { handleOrderInfoModal, handleProfileEditModal } from '../../../features/modals/modalsSlice'
import { DocsEditFields } from '../../../types/entities/user.types'
import { ModalContentProps } from '../../Modal'
import { InputField } from '../../InputField'
import { getCookie } from '../../../utils/CookieUtil'
import { YellowButton } from '../../YellowButton'
import { changeDouments } from '../../../features/login/docsEditSlice'
import { getProfile, updateData } from '../../../features/profile/profileSlice'
import { getDocuments } from '../../../features/login/docsEditSlice'
import { cardAdd, handleEditProfileGender, handleProfileForm, setDefaultProfileForm , changeGender, changeFIO, handleBankForm, sendSignPhone} from '../../../features/profile/profileSlice'
import { useInterval } from '../../../hooks/useInterval'
import { setCodeFreezedSecs,setCodeIsFreezed, resetDocsEditCodeStatus, sendEditSignCode } from '../../../features/login/docsEditSlice'
import { ProfileEditTextFields, ProfileData } from '../../../types/entities/user.types'
import useTheme from '../../../hooks/useAppColor'
import { BILLACC_MASK, BIC_MASK, CARD_MASK, PASSPORT_MASK, DATE_MASK, } from '../../../config/masks'
import { formatPhoneNumber } from '../../../utils/formatePhone'
import { update } from '@react-spring/web'



export const ProfileModalContent: FC<ModalContentProps> = ({ handleModal, level }) => {
    const dispatch = useAppDispatch()
    const getTheme = useTheme()
    const { auth } = useAppSelector(state => state.docsEditAccept)

    
    const { form, data, edit_form } = useAppSelector(state => state.profile)
    const {bd_form} = useAppSelector(state => state.profile.bank_data_form)

    const formAndDataEqual = Object.keys(form).every((key) => form[key as keyof ProfileEditTextFields] === data[key as keyof ProfileData])

    const [isDocsEdit, setIsDocsEdit] = useState<boolean>(false)
    const [pressed, setPressed] = useState(false)
    const [isSMSAccept, setIsSMSAccept] = useState<boolean>(false)
    
    const [code, setCode] = useState<string[]>(["", "", "", ""])
    const [sended, setSended] = useState(false);
    const [docsDisabled, setDocsDisabled] = useState<boolean>(false)
    const inputRefs = useRef<HTMLInputElement[] | null[]>([]);

    const handleBackspace = (index: number) => {
        if (index > 0 && code[index] === '') {
            const prevInput = inputRefs.current[index - 1];
            prevInput?.focus();
        }

        setCode(prevCode => {
            const newCode = [...prevCode];
            newCode[index] = '';
            return newCode;
        });
    };

    const handleCodeInput = (text: string, index: number) => {
            if (/^\d*$/.test(text) && text.length <= 1) {
                setCode(prevCode => {
                    const newCode = [...prevCode];
                    newCode[index] = text;  
    
                    if (text !== '' && index < inputRefs.current.length - 1) {
                        const nextInput = inputRefs.current[index + 1];
    
    
                        nextInput?.focus();
                    } else if (text === '' && index > 0) {
                        const prevInput = inputRefs.current[index - 1];
                        prevInput?.focus();
                    }
    
                    return newCode;
                });
            }
        };
    
        useInterval(() => {
            if (auth.code_options.is_freezed && auth.code_options.freezed_sec > 0) {
                dispatch(setCodeFreezedSecs(auth.code_options.freezed_sec - 1))
            } else {
                dispatch(setCodeIsFreezed(false))
                dispatch(setCodeFreezedSecs(0))
            }
        }, 1000);

        const handleNewCode = () => {
                if (!auth.code_options.is_freezed) {
                    dispatch(sendSignPhone({ username: data.phone}))
                    dispatch(setCodeIsFreezed(true))
                    dispatch(setCodeFreezedSecs(5))
                    setCode(["", "", "", ""])
                    return;
                }
            }
    
        useEffect(() => {
    
            if (code.filter(item => item !== "").length === 4) {                
                if (!sended && (auth.success.code === null)) {
                    setSended(true)
                    dispatch(
                        sendEditSignCode({
                          username: data.phone,
                          password: code.join(""),
                        })
                      )
                        .unwrap()
                        .then((response) => {
                            setIsSMSAccept(true)
                            dispatch(getDocuments())
                            .unwrap()
                            .then((response) => {
                                dispatch(updateData(response))
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                }
                return
            }
            if (auth.success.code === false) {
                dispatch(resetDocsEditCodeStatus())
            }
            setSended(false)
        }, [code, sended, auth.success.code])



    const sendEditProfile = () => {
        const normalizedCard = form.card_number.split(" ").join("")
        const numberedCard = Number(normalizedCard)
        if(normalizedCard !== data.card_number.split(" ").join(""))
            dispatch(cardAdd({ card_number: numberedCard }))
        if(form.first_name !== data.first_name || form.last_name !== data.last_name || form.subname !== data.subname)
            {
                dispatch(changeFIO({first_name: form.first_name, last_name: form.last_name, subname: form.subname}))
                dispatch(getProfile())
            }
        if(form.gender !== data.gender)
            dispatch(changeGender({gender: form.gender}))
    }
    
    const sendEditDocuments = () => {

        const reqdata = {
            "username": data.phone,
            "address":  bd_form.address,
            "correspond_acc": bd_form.correspond_acc,
            "sett_acc": bd_form.sett_acc,
            "bik": bd_form.bik,
            "passport_issue_date": bd_form.passport_issue_date,
            "passport_issued_by": bd_form.passport_issued_by,
            "passport_series": bd_form.passport_series,
            "passport_id": bd_form.passport_id,
            "email": bd_form.email
        }
            dispatch(changeDouments(reqdata))
            setDocsDisabled(true)
    }

    return (
        <BorderedPageLayout
            modal={{ level: level || 1 }}
            handleModal={handleModal}
            contentClassName='f-column gap-40'>
            <div className="d-f jc-between">
                <div onClick={handleModal} className="textButton fz-l">Закрыть</div>
                <div className='f-row-betw gap-10'>
                    <p className={`${getTheme("c-dark", "c-lg")} ${isDocsEdit ? "fz-s" : "fz-m s-n active"} fw-6`} onClick={() => setIsDocsEdit(false)}>Личные данные</p>
                    <p className={`${getTheme("c-dark", "c-lg")} ${isDocsEdit ? "fz-m s-n active" : "fz-s"} fw-6`} onClick={() => setIsDocsEdit(true)}>Документы</p></div>
                <div className='f-025'></div>
            </div>
            {
                !isDocsEdit ?
                <>
                    <div className="f-column gap-30">
                        <InputField
                            error={""}
                            value={form.last_name}
                            onChange={e => dispatch(handleProfileForm({ key: "last_name", val: e.target.value }))}
                            placeholder='Иванов'
                            label='Фамилия' />
                        <InputField
                            error={""}
                            value={form.first_name}
                            onChange={e => dispatch(handleProfileForm({ key: "first_name", val: e.target.value }))}
                            placeholder='Иван'
                            label='Имя' />
                        <InputField
                            value={form.subname}
                            onChange={e => dispatch(handleProfileForm({ key: "subname", val: e.target.value }))}
                            placeholder='Иванович'
                            label='Отчество' />
                        <InputField
                            value={form.card_number == "0" ? "" : form.card_number}
                            onChange={e => dispatch(handleProfileForm({ key: "card_number", val: e.target.value }))}
                            mask={CARD_MASK}
                            placeholder='0000 0000 0000 0000'
                            label='Номер карты' />
                        <div className="f-column gap-15">
                            <p className='fz-m fw-5'>Ваш пол</p>
                            <div className="f-row-betw gap-15">
                                <YellowButton onClick={() => dispatch(handleEditProfileGender(true))} isFilled={form.gender} className={`f-1 ${getTheme("c-dark", "c-white")}`}>Мужчина</YellowButton>
                                <YellowButton onClick={() => dispatch(handleEditProfileGender(false))} isFilled={!form.gender} className={`f-1 ${getTheme("c-dark", "c-white")}`}>Женщина</YellowButton>
                            </div>
                        </div>
                    </div>
                    <div className="f-column gap-5">
                    <YellowButton
                        disabled={formAndDataEqual}
                        onClick={sendEditProfile}
                        loading={edit_form.sending}>
                        Сохранить
                    </YellowButton>
                    {
                        edit_form.err ? <p className='fz-m c-error'>{edit_form.err}</p> : null
                    }
                    </div>
                </>
            :
            !pressed ?
            <div className="f-column gap-15">
                <p className={`txt-center fz-m fw-5 ${getTheme("", "c-white")}`}> Для редактирования паспортных и банковских данных необходимо пройти подтверждение через СМС</p>
                <p className={`txt-center fz-m fw-5 ${getTheme("", "c-white")}`}> СМС будет выслано на номер:<br/> {formatPhoneNumber(data.phone)}</p>
                <YellowButton onClick={() => {handleNewCode(); setPressed(true)}}>Подтвердить</YellowButton>
            </div>
            :
            !isSMSAccept ?
            <div className="f-column gap-15">
                <div className="f-column gap-10">
                    <p className={`fz-m fw-5 ${getTheme("", "c-white")} ${auth.success.code === false ? "c-error" : null}`}> {
                        auth.success.code === false ? auth.errors.code : ` Введите код из СМС ${formatPhoneNumber(data.phone)}`}
                    </p>
                    <div className="f-row-betw gap-10">
                        {
                            code.map((item, index) => (
                                <InputField
                                    inputType={"number"}
                                    value={item}
                                    onChange={(e) => handleCodeInput(e.target.value, index)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Backspace') {
                                            handleBackspace(index);
                                        }
                                    }}
                                    refInput={(el) => (inputRefs.current[index] = el)}
                                    maxLength={1}
                                    className='txt-center code-input'
                                    label={''} />
                            ))
                                    }
                    </div>
                </div>
            <YellowButton disabled={auth.code_options.is_freezed} onClick={handleNewCode}>Отправить код еще раз {auth.code_options.is_freezed ? `(${auth.code_options.freezed_sec} сек)` : null}</YellowButton>
            </div>
            :
           <>
            <div className="f-column gap-30">
                <h2 className={`title ${getTheme("", "c-white")}`}>Паспортные данные</h2>
                <InputField
                    value={bd_form.passport_series + bd_form.passport_id}
                    onChange={e => dispatch(handleBankForm({ key: "passport_numbers", val: e.target.value }))}
                    maskPlaceholder={"СССС НННННН"}
                    mask={PASSPORT_MASK}
                    placeholder='СССС НННННН'
                    label='Серия и номер паспорта' />
                <InputField
                    value={bd_form.passport_issue_date}
                    onChange={e => dispatch(handleBankForm({ key: "passport_issue_date", val: e.target.value }))}
                    maskPlaceholder={"ДД.ММ.ГГГГ"}
                    mask={DATE_MASK}
                    placeholder={"ДД.ММ.ГГГГ"}
                    label='Когда выдан' />
                <InputField
                    value={bd_form.passport_issued_by}
                    onChange={e => dispatch(handleBankForm({ key: "passport_issued_by", val: e.target.value }))}
                    placeholder='Кем выдан паспорт'
                    label='Кем выдан' />
                <InputField
                    value={bd_form.email}
                    onChange={e => dispatch(handleBankForm({ key: "email", val: e.target.value }))}
                    placeholder='E-mail'
                    label='E-mail' />
                <InputField
                    value={bd_form.address}
                    onChange={e => dispatch(handleBankForm({ key: "address", val: e.target.value }))}
                    placeholder='Адрес'
                    label='Адрес регистрации' />
                 <h2 className={`title ${getTheme("", "c-white")}`}>Банковские данные</h2>
                <InputField
                    value={bd_form.sett_acc}
                    onChange={e => dispatch(handleBankForm({ key: "sett_acc", val: e.target.value }))}
                    placeholder='Р/Счет'
                    mask={BILLACC_MASK}
                    maskPlaceholder={"0000 0000 0000 0000 0000"}
                    label='Рассчетный счет' />
                <InputField
                    value={bd_form.correspond_acc}
                    onChange={e => dispatch(handleBankForm({ key: "correspond_acc", val: e.target.value }))}
                    placeholder="К/Счет"
                    mask={BILLACC_MASK}
                    maskPlaceholder={"0000 0000 0000 0000 0000"}
                    label='Корреспондентский счёт' />
                <InputField
                    value={bd_form.bik}
                    mask={BIC_MASK}
                    maskPlaceholder={"000 000 000"}
                    onChange={e => dispatch(handleBankForm({ key: "bik", val: e.target.value }))}
                    placeholder='БИК'
                    label='БИК' />
            </div>
            <div className="f-column gap-5">
                <YellowButton
                    onClick={sendEditDocuments}
                    loading={edit_form.sending}
                    disabled={docsDisabled}>
                  
                    Сохранить
                </YellowButton>
                {
                    edit_form.err ? <p className='fz-m c-error'>{edit_form.err}</p> : null
                }
            </div>
        </>
            }
        </BorderedPageLayout >
    )
}
