import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { AnalysisApi } from "../../types/entities/analysis.types";

export type CartItemType = Pick<AnalysisApi, "id" | "name" | "cost">

type CartSliceState = {
    items: CartItemType[]
    totalPrice: number
}

const initialState: CartSliceState = {
    items: [],
    totalPrice: 0
}
export const CartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<CartItemType>) => {
            
            state.items = [
                ...state.items,
                action.payload
            ]
            state.totalPrice += action.payload.cost
        },
        removeProduct: (state, action: PayloadAction<{id:number, cost: number}>) => {
            state.items = state.items.filter(item => item.id !== action.payload.id)
            state.totalPrice -= action.payload.cost
        },
        clearCart: (state) => {
            state.items = []
            state.totalPrice = 0
        }
    }
})

export const {
    addToCart,
    removeProduct,
    clearCart
} = CartSlice.actions


export const cartReducer = CartSlice.reducer