import React, { useEffect, useState, useRef } from 'react'
import { InputField } from '../../components/InputField'
import { YellowButton } from '../../components/YellowButton'
import { BorderedPageLayout } from '../BorderedPageLayout'
import { DocumentIcon } from '../../icons'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setHasDocs, sendSignPhone, handleCreateBankForm} from '../../features/profile/profileSlice'    
import { setCodeIsFreezed, setCodeFreezedSecs, resetDocsAcceptCodeStatus, sendSignCode} from '../../features/login/docsAcceptSlice'

import { useInterval } from '../../hooks/useInterval'
import useTheme from '../../hooks/useAppColor'
import { deleteCookie, getCookie } from '../../utils/CookieUtil'
import { PersonBankData } from '../../types/entities/user.types'
import { BILLACC_MASK, INN_MASK, SNILS_MASK, BIC_MASK } from '../../config/masks'

export const DocumentAccept = () => {
    const dispatch = useAppDispatch()
    const { auth } = useAppSelector(state => state.docsAccept)
    const { docs_url } = useAppSelector(state => state.profile)
    const getTheme = useTheme()

    const [code, setCode] = useState<string[]>(["", "", "", ""])
    const [sended, setSended] = useState(false);
    const [pressed, setPressed] = useState(false)
    const [filled, setFilled] = useState(false)
    const inputRefs = useRef<HTMLInputElement[] | null[]>([]);

    const { text_fields, disabled, sending, err } = useAppSelector(state => state.profile.bank_data_form)
    
    const phoneNumbers = getCookie("phone")


    const handleBackspace = (index: number) => {
        if (index > 0 && code[index] === '') {
            const prevInput = inputRefs.current[index - 1];
            prevInput?.focus();
        }

        setCode(prevCode => {
            const newCode = [...prevCode];
            newCode[index] = '';
            return newCode;
        });
    };

    const handleCodeInput = (text: string, index: number) => {
            if (/^\d*$/.test(text) && text.length <= 1) {
                setCode(prevCode => {
                    const newCode = [...prevCode];
                    newCode[index] = text;  
    
                    if (text !== '' && index < inputRefs.current.length - 1) {
                        const nextInput = inputRefs.current[index + 1];
    
    
                        nextInput?.focus();
                    } else if (text === '' && index > 0) {
                        const prevInput = inputRefs.current[index - 1];
                        prevInput?.focus();
                    }
    
                    return newCode;
                });
            }
        };
    
        useInterval(() => {
            if (auth.code_options.is_freezed && auth.code_options.freezed_sec > 0) {
                dispatch(setCodeFreezedSecs(auth.code_options.freezed_sec - 1))
            } else {
                dispatch(setCodeIsFreezed(false))
                dispatch(setCodeFreezedSecs(0))
            }
        }, 1000);

        const handleNewCode = () => {
                if (!auth.code_options.is_freezed) {
                    dispatch(sendSignPhone({ username: phoneNumbers.phone}))
                    dispatch(setCodeIsFreezed(true))
                    dispatch(setCodeFreezedSecs(5))
                    setCode(["", "", "", ""])
                    return;
                }
            }
    
        useEffect(() => {
    
            if (code.filter(item => item !== "").length === 4) {                
                if (!sended && (auth.success.code === null)) {
                    setSended(true)
                    acceptDocs()
                }
                return
            }
            if (auth.success.code === false) {
                dispatch(resetDocsAcceptCodeStatus())
            }
            setSended(false)
        }, [code, sended, auth.success.code])
    

        const handleCreateBankData = () => {
            dispatch(sendSignPhone({ username: phoneNumbers.phone}))
            dispatch(setCodeIsFreezed(true))
            setFilled(!filled)

        }

    const acceptDocs = () => {
        const person_bank_data: PersonBankData = {
            address: text_fields.address,
            INN: text_fields.INN,
            SNILS: text_fields.SNILS,
            sett_acc: text_fields.sett_acc,
            correspond_acc: text_fields.correspond_acc,
            bik: text_fields.bik,
            //signature: ""
        }


        dispatch(sendSignCode({
            username: phoneNumbers.phone,
            password: code.join(""),
            bank_data: person_bank_data,
            doc_url: docs_url
        }))

        dispatch(setHasDocs(true))
        // deleteCookie("phone")
    }

    function handleSignDoc(){
        setPressed(!pressed)
    }


    return (
        <BorderedPageLayout
            contentClassName={"f-column-betw"}>
            <div className="f-column gap-40">
                <h2 className={`title fw-6 ${getTheme("", "c-white")}`}>Подпишите договор</h2>
                <p className='fz-m c-lg text'>
                    Нажмите кнопку <span className={"c-dg fw-6"}>Подписать</span>, чтобы подписать договор. Нажмите <span className={"c-dg fw-6"}>Скачать</span>, чтобы сохранить документ на ваше устройство.
                </p>
                <div className="f-column gap-25">
                    <a href={docs_url || "/media/dogovor/doc_blank.pdf"} target={"_blank"} className="d-f al-center gap-15">
                        <DocumentIcon />
                        <p className={`text fw-5 ${getTheme("", "c-white")}`}>Просмотреть агентский договор</p>
                    </a>
                    <div className="f-column gap-15">
                        {pressed ? 
                                filled ? 
                            <BorderedPageLayout
                            contentClassName={"f-column-betw"}
                            top={
                                <div className='f-row-betw'>
                                    <div></div>
                                    <h2 className={`title fw-6 ${getTheme("", "c-white")}`}>Код из СМС</h2>
                                    <div></div>
                                </div>
                            }>
                            <div className="f-column gap-15">
                                <div className="f-column gap-10">
                                    <p className={`fz-m fw-5 ${getTheme("", "c-white")} ${auth.success.code === false ? "c-error" : null}`}> {
                                        auth.success.code === false ? auth.errors.code : ` Введите код из СМС ${auth.form.maskedPhone}`}
                                    </p>
                                    <div className="f-row-betw gap-10">
                                        {
                                            code.map((item, index) => (
                                                <InputField
                                                    inputType={"number"}
                                                    value={item}
                                                    onChange={(e) => handleCodeInput(e.target.value, index)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Backspace') {
                                                            handleBackspace(index);
                                                        }
                                                    }}
                                                    refInput={(el) => (inputRefs.current[index] = el)}
                                                    maxLength={1}
                                                    className='txt-center code-input'
                                                    label={''} />
                                            ))
                                                    }
                                    </div>
                                </div>
                            <YellowButton disabled={auth.code_options.is_freezed} onClick={handleNewCode}>Отправить код еще раз {auth.code_options.is_freezed ? `(${auth.code_options.freezed_sec} сек)` : null}</YellowButton>
                            </div>
                        </BorderedPageLayout>:
                        
                        <BorderedPageLayout
                        contentClassName={"f-column-betw"}>
                        <div className="f-column gap-40">
                            <h2 className={`title fw-6 ${getTheme("", "c-white")}`}>Укажите рассчетные данные</h2>
                            <div className="f-column gap-30">
                                <InputField
                                    value={text_fields.address}
                                    onChange={e => dispatch(handleCreateBankForm({ key: "address", val: e.target.value }))}
                                    placeholder='Адрес'
                                    label='Адрес регистрации' />
                                <InputField
                                    value={text_fields.INN}
                                    onChange={e => dispatch(handleCreateBankForm({ key: "INN", val: e.target.value }))}
                                    placeholder='ИНН'
                                    mask={INN_MASK}
                                    maskPlaceholder={"0000 0000 0000"}
                                    label='ИНН' />
                                <InputField
                                    value={text_fields.SNILS}
                                    onChange={e => dispatch(handleCreateBankForm({ key: "SNILS", val: e.target.value }))}
                                    placeholder='СНИЛС'
                                    mask={SNILS_MASK}
                                    maskPlaceholder={"000-000-000 00"}
                                    label='СНИЛС' />
                                <InputField
                                    value={text_fields.sett_acc}
                                    onChange={e => dispatch(handleCreateBankForm({ key: "sett_acc", val: e.target.value }))}
                                    placeholder='Р/Счет'
                                    mask={BILLACC_MASK}
                                    maskPlaceholder={"0000 0000 0000 0000 0000"}
                                    label='Рассчетный счет' />
                                <InputField
                                    value={text_fields.correspond_acc}
                                    onChange={e => dispatch(handleCreateBankForm({ key: "correspond_acc", val: e.target.value }))}
                                    placeholder="К/Счет"
                                    mask={BILLACC_MASK}
                                    maskPlaceholder={"0000 0000 0000 0000 0000"}
                                    label='Корреспондентский счёт' />
                                <InputField
                                    value={text_fields.bik}
                                    mask={BIC_MASK}
                                    maskPlaceholder={"000 000 000"}
                                    onChange={e => dispatch(handleCreateBankForm({ key: "bik", val: e.target.value }))}
                                    placeholder='БИК'
                                    label='БИК' />
                            </div>
                            <div className="f-column gap-5">
                                <YellowButton disabled={disabled} onClick={handleCreateBankData} loading={sending}>Подтвердить</YellowButton>
                                {
                                    err ? <p className='fz-m c-error'>{err}</p> : null
                                }
                            </div>
                        </div>
                    </BorderedPageLayout> :
                        <div className="f-column gap-15">
                            <YellowButton onClick={handleSignDoc}>Подписать</YellowButton>
                        <div className="w-100p d-f jc-center">
                            <a href={docs_url || "/media/dogovor/doc_blank.pdf"} target={"_blank"} download={true} className='c-yellow fz-l fw-5'>Скачать</a>
                        </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </BorderedPageLayout >
    )
}