import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { UserApi } from "../../http/api/user.api";
import { AuthAcceptReq, AuthSignDocxReq, AuthSignDocxRes } from "../../types/api/user.api.types";
import { deleteTokens, storeTokens } from "../../utils/storeTokens";
import { getCookie } from "../../utils/CookieUtil";

const phone = getCookie("phone")

type docsAcceptSliceType = {
    access_token: string
    token: {
        valid: boolean | null,
        checking: boolean
    },
    auth: {
        loading: boolean,
        success: {
            phone: boolean | null
            code: boolean | null
        },
        code_options: {
            is_freezed: boolean
            freezed_sec: number
        },
        form: {
            maskedPhone: string
            phone: string
            password: string
        },
        errors: {
            phone: string
            code: string
        }
    }
}

const initialState: docsAcceptSliceType = {
    access_token: "",
    token: {
        checking: true,
        valid: null
    },
    auth: {
        loading: false,
        success: {
            phone: phone,
            code: null
        },
        code_options: {
            is_freezed: false,
            freezed_sec: 0
        },
        form: {
            maskedPhone: phone?.maskedPhone || '+7',
            phone: phone?.phone || '',
            password: ""
        },
        errors: {
            phone: "",
            code: ""
        }
    }
}

export const sendSignCode = createAsyncThunk(
        'docs_sign/code',
        async (req: AuthSignDocxReq, { dispatch }) => {
            const res: AxiosResponse<AuthSignDocxRes> = await UserApi.SignDocxCode(req)
            if (!res.data) {
                throw new Error("Ошибка сервера!")
            }
            if (res.status === 401) {
                throw new Error("Неверный код!")
            }
    
            return res.data
        }
    )

export const docsAcceptSlice = createSlice({
    name: "docsAccept",
    initialState,
    reducers: {
        handleDocsAcceptForm: (state, action: PayloadAction<{ key: keyof typeof initialState.auth.form, val: string }>) => {
            if (state.auth.errors.code) {
                state.auth.errors.code = ""
            }
            if (state.auth.errors.phone) {
                state.auth.errors.phone = ""
            }
            state.auth.form[action.payload.key] = action.payload.val
        },
        setCodeIsFreezed: (state, action: PayloadAction<boolean>) => {
            state.auth.code_options.is_freezed = action.payload
        },
        setCodeFreezedSecs: (state, action: PayloadAction<number>) => {
            state.auth.code_options.freezed_sec = action.payload
        },
        resetDocsAcceptForm: (state) => {
            state.auth = initialState.auth
        },
        setValidToken: (state, action: PayloadAction<boolean>) => {
            state.token.valid = action.payload
        },
        resetDocsAcceptCodeStatus: (state) => {
            state.auth.success.code = null
        },
        resetDocsAcceptPhoneStatus: (state) => {
            state.auth.success.phone = null
        },


    },
    extraReducers: (builder) => {
        //SEND PHONE IN DocsAccept
        builder.addCase(sendSignCode.pending, (state, action) => {
            state.auth.loading = true
            state.auth.success.phone = null
            state.auth.errors.phone = ""
        })
        
        builder.addCase(sendSignCode.fulfilled, (state, action) => {
            storeTokens({ refresh: action.payload.refresh, access: action.payload.access })
            state.auth.loading = false
            state.auth.success.code = true
            state.token.valid = true
            state.auth.form = initialState.auth.form

        })
        builder.addCase(sendSignCode.rejected, (state, action) => {
            const isBadCode = action.error.code === "ERR_BAD_REQUEST"
            state.auth.loading = false
            state.auth.success.code = false
            state.auth.errors.code = String(isBadCode ? "Неверный код" : action.error.message)
        })
    },
})

export const {
    handleDocsAcceptForm,
    resetDocsAcceptForm,
    resetDocsAcceptCodeStatus,
    setValidToken,
    resetDocsAcceptPhoneStatus,
    setCodeFreezedSecs,
    setCodeIsFreezed
} = docsAcceptSlice.actions


export const docsAcceptReducer = docsAcceptSlice.reducer